<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-12 col-lg-6">
          Публикация
        </div>
        <div class="col-12 col-lg-2">
          Создана
        </div>
        <div class="col-12 col-lg-2">
          Опубликована
        </div>
        <div class="col-12 col-lg-2 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="post in posts"
        :key="post.slug"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-6">
                <div class="d-flex align-items-center">
                  <div
                    v-if="post.cover_image && post.cover_image.url"
                    class="content-image-wrap"
                  >
                    <img
                      :src="post.cover_image.url"
                      alt="cover"
                    >
                  </div>
                  <div class="content-text-wrap">
                    {{ post.title }}<br>
                    <small>{{ post.slug }}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-2">
                {{ post.created_at | momentFormat }}
              </div>
              <div class="col-12 col-lg-2">
                <b-form-checkbox
                  switch
                  :checked="post.is_published"
                  @change="changePublish(post)"
                />
              </div>
              <div class="col-12 col-lg-2">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(post)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    @click.prevent="openDeletePopup(post)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-post-popup
      v-if="showEditPopup && currentPostSlug"
      :key="currentPostSlug"
      :post-slug="currentPostSlug"
      @updated="$emit('updated', $event)"
      @hidden="hideEditPopup"
    />
    <delete-post-popup
      v-if="showDeletePopup && currentPostSlug"
      :key="currentPostSlug"
      :post-slug="currentPostSlug"
      @deleted="$emit('deleted', $event)"
      @hidden="hideDeletePopup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BFormCheckbox, BButton } from 'bootstrap-vue'
import Blog from '@/api/http/models/blog/Blog'

import EditPostPopup from './EditPostPopup.vue'
import DeletePostPopup from './DeletePostPopup.vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    EditPostPopup,
    DeletePostPopup,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showDeletePopup: false,
      currentPostSlug: null,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  methods: {
    async changePublish(post) {
      await Blog.activate(this.currentSite.slug, post.slug, !post.is_published)
    },
    openEditPopup(post) {
      this.showEditPopup = true
      this.currentPostSlug = post.slug
    },
    hideEditPopup() {
      this.showEditPopup = false
      this.currentPostSlug = null
    },
    openDeletePopup(post) {
      this.showDeletePopup = true
      this.currentPostSlug = post.slug
    },
    hideDeletePopup() {
      this.showDeletePopup = false
      this.currentPostSlug = null
    },
  },
}
</script>
